<template>
  <div class="ProfilePage">
    <CreekProfilePage 
      :userName="userName"
    />
  </div>
</template>

<script>
import CreekProfilePage from '@/lib/creek/views/ProfilePage'
export default {
  name: 'ProfilePage',
  data(){
    return {}
  },
  props: {
    showName: {
      type: String
    }
  },
  methods: {

  }
}
</script>